import { Grid  } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../style";
import logoPnj from "../../images/assets/logo.png";



// markup
export const ExpiredLink = ( ) => {
  const classes = useStyles();
   return (
    <>
      <Grid
        className={classes.bodyContent}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <img
            className={classes.logoMain}
            src={logoPnj}
            alt="PNJ Việt Nam"
            title="PNJ Việt Nam"
          />
        </Grid>

        <Grid
          item
          xs={10}
          container
          justifyContent="center"
          alignItems="center"
        >
          <div className="thank-title ">
             Đường link đã quá hạn. Quý khách xin vui lòng liên hệ hotline để gửi lời đánh giá
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <div className="thankyou-action thankyou-action-mobile">
            <a href="tel:1800545457">Gọi Hotline PNJ (Miễn phí)</a>
          </div>
          <div className="thankyou-action thankyou-action-desktop">
            <a>Liên hệ Hotline (Miễn phí): 1800545457 </a>
          </div>
        </Grid>
      </Grid>
      <div className={classes.bodyBackground} />
    </>
  );
};
